.background-login {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13121a;
  width: 100%;
}

.login-box-splash {
  background-image: url("../../../public/img/login-splash-background.png");
  background-position: center center;
  background-size: cover;
  position: relative;
  width: 60%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box-splash::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 8px;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #fc9ac3 0%,
    #000cfa 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
}

.box-splash {
  width: 100%;
  max-width: 480px;
}

.title-simbol {
  width: 260px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 1px solid #d5d5d5;
  height: 50px;
  padding: 4px;
}

.title-simbol-circle {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: solid 1px #d5d5d5;
}

.box-splash h1 {
  font-weight: 400;
  color: #d5d5d5;
}

.box-splash h1 b {
  font-weight: 700;
}

.container-login-box-white {
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13121a;
}

.container-login-box-white h1,
.container-login-box-white h2,
.container-login-box-white h3,
.container-login-box-white h4,
.container-login-box-white h5,
.container-login-box-white h6,
.container-login-box-white label,
.container-login-box-white p {
  color: #eaeaea;
}

.container-login-box-white .box-white {
  width: 100%;
  max-width: 380px;
  margin-top: -150px;
}

.logo-login {
  display: none;
}

@media screen and (max-width: 1200px) {
  .background-login {
    height: auto;
    flex-direction: column;
  }

  .login-box-splash {
    display: none;
  }

  .container-login-box-white {
    width: 100%;
    height: 100vh;
    background-image: url("../../../public/img/login-splash-background.png");
  }

  .container-login-box-white .box-white {
    background-color: #13121a;
    padding: 60px;
    margin-top: 0;
    position: relative;
    max-width: 480px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}

  .container-login-box-white .box-white::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 8px;
    height: 100%;
    background: linear-gradient(
      to bottom,
      #fc9ac3 0%,
      #000cfa 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  }

  .logo-login {
    display: block;
  }
}
