.right-overlay{
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
}
.right-content{
    width: 50%;
    height: 100%;
    z-index: 9998;
    top: 0%;
    right: 0%;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;
    animation: slideRight 280ms ease-in;
}
@media screen and (min-width: 1200px){
    .right-content{
        width: 40%;
    }
}
@media screen and (max-width: 992px){
    .right-content{
        width: 60%;
    }
}
@media screen and (max-width: 500px){
    .right-content{
        width: 95%;
    }
}
.right-header{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003dff+0,7415ff+66 */
    background: #003dff; /* Old browsers */
    background: -moz-linear-gradient(left, #003dff 0%, #7415ff 66%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #003dff 0%,#7415ff 66%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #003dff 0%,#7415ff 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003dff', endColorstr='#7415ff',GradientType=1 ); /* IE6-9 */
    padding: 20px 40px;
}
.right-header h6{
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
}
.right-header .btn-close-2{
    width: 25px;
    height: 25px;
    line-height: 23px;
    background-color: rgba(256,256,256,0.1);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    -o-border-radius: 100px;
    padding: 0;
    margin: 0;
    transition: all 180ms;
    -o-transition: all 180ms;
    -webkit-transition: all 180ms;
    -moz-transition: all 180ms;
}
.right-header .btn-close-2:hover{
    transform: scale(1.05);
    -o-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    background-color: rgba(256,256,256,0.3);
}
.right-header .btn-close-2:hover i{
    color: rgba(256,256,256,0.6);
}
.right-header .btn-close-2 i{
    color: #fff;
    margin: 0;
    padding: 0;
    line-height: 25px;
}
.right-body{
    padding: 30px 40px;
    flex: 1 1 0%;
    min-height: auto;
    overflow: auto;
}
.right-footer{
    border-top: solid 1px #ccc;
    background-color: #fdfdfd;
    padding: 15px 40px;
}