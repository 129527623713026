@media (min-width: 991px) and (max-width: 1199px) {
    .total-sales-chart {
        height: 320px;
    }
}

@media (min-width: 1199px) and (max-width: 1350px) {
    .total-sales-chart {
        height: 380px;
    }
}

@media (min-width: 1350px) and (max-width: 1600px) {
    .total-sales-chart {
        height: 450px;
    }
}

@media (min-width: 1600px) and (max-width: 1921px) {
    .total-sales-chart {
        height: 500px;
    }
}