body {
  background-color: #eaeaea !important;
}

.custom-bread-crumb {
  background: linear-gradient(
    to right,
    #000cfa 0%,
    #fc9ac3 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  height: auto;
  padding: 5px 0;
}

.custom-bread-crumb ol {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-bread-crumb ol li {
  display: inline;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.box-page {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  background-color: white;
  border: solid 1px #d5d5d5;
  padding: 40px;
  margin-bottom: 60px;
}

/* Define el color de fondo al hacer hover */
.custom-dropdown .dropdown-menu a.dropdown-item:hover {
    background-color: #f8f9fa; /* Cambia a tu color deseado */
    color: #212529; /* Cambia a tu color deseado */
  }

/* Estilos personalizados para la tabla */
.custom-datatable .p-datatable .p-datatable-tbody > tr:hover {
    background-color: #f5f5f5; /* Cambiar el color de fondo al hacer hover */
  }
  
  .custom-datatable .p-datatable .p-datatable-tbody > tr > td {
    border: none; /* Eliminar los bordes de las celdas */
  }
  
  .custom-datatable .p-datatable .p-datatable-tbody > tr > td:first-child {
    border-left: none; /* Eliminar el borde izquierdo de la primera celda */
  }
  
  .custom-datatable .p-datatable .p-datatable-tbody > tr > td:last-child {
    border-right: none; /* Eliminar el borde derecho de la última celda */
  }
  
  .custom-datatable .p-datatable .p-datatable-thead > tr > th {
    border: none; /* Eliminar los bordes de las celdas del encabezado */
  }
  
  .custom-datatable .p-datatable .p-datatable-thead > tr > th:first-child {
    border-left: none; /* Eliminar el borde izquierdo de la primera celda del encabezado */
  }
  
  .custom-datatable .p-datatable .p-datatable-thead > tr > th:last-child {
    border-right: none; /* Eliminar el borde derecho de la última celda del encabezado */
  }
  
  .custom-datatable .p-datatable .p-datatable-thead > tr > th {
    border-bottom: none; /* Eliminar los bordes inferiores de las celdas del encabezado */
  }
  