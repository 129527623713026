.navbar {
    background: #18283b;
    display: block;
}
.navbar-brand {
    padding: 0 0px;
    margin-top: -10px;
} 
.navbar-toggle {
    margin-top: 23px;
    padding: 9px 10px !important;
}  
@media (min-width: 768px) {
    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 27px;
        color: #fff;
    }
}
.collapse-menu{
    width: 100%;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 998;
}
.collapse-menu .collapse-menu-content{
    width: 320px;
    height: 100%;
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    animation: collapsemenu;
    animation-duration: 280ms;
    animation-fill-mode: both;
}
@keyframes collapsemenu{
    from{
        left: -20%;
    }
    to{
        left: 0%;
    }
    
    -webkit-from{
        left: -20%;
    }
    -webkit-to{
        left: 0%;
    }
}
.collapse-menu .collapse-menu-content .close{
    display: block;
    height: 66px;
    line-height: 66px;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    color: #212529;
    padding-left: 30px;
    text-shadow: none;
    opacity: 1;
    margin-bottom: 0px;
}
.collapse-menu .collapse-menu-content .close:hover{
    opacity: 1;
}
.collapse-menu .collapse-menu-content .close button{
    background-color: transparent;
    border: 0;
    padding: 0;
}
.collapse-menu .collapse-menu-content .close button .fas{
    color: #003BFF;
}
.collapse-menu .collapse-menu-content .content{
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0 30px 0 30px;
    margin-bottom: 60px;
}
.collapse-menu .collapse-menu-content .content li{
    list-style: none;
    line-height: 30px;
}
.collapse-menu .collapse-menu-content .content li.header{
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    color: #666;
}
.collapse-menu .collapse-menu-content .content li a{
    color: #999;
    text-decoration: none;
}
.collapse-menu .collapse-menu-content .content li a:hover{
    color: #003BFF;
    cursor: pointer;
}

.logo img {
    width: 150px;
}